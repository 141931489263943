import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ListeAmazonComponent } from './liste-amazon/liste-amazon.component';
import { FourOhFourComponent } from './four-oh-four/four-oh-four.component';
import { AmazonService } from 'src/app/services/Amazon.service';
import { FooterComponent } from './footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { Routes,RouterModule } from '@angular/router';
import { ProductDetailComponent } from './product-detail/product-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    ListeAmazonComponent,
    FourOhFourComponent,
    FooterComponent,
    ProductDetailComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    AppRoutingModule
  ],
  providers: [
    AmazonService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
