import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BeanAmazonProduct} from "./BeanAmazonProduct";
import { Observable, of } from 'rxjs';

@Injectable()
export class AmazonService {

    public productArray: BeanAmazonProduct[] = [];
    public actualFilename: String;
    public lastFilename: String;
    public dateOfData: Date;
    constructor(private http:HttpClient){
    }

    decodePythonDate(pythonDateString): Date {
        // Extrait les composants de la date (année, mois, jour, heure, minute, seconde) de la chaîne
        const year = parseInt(pythonDateString.substring(0, 4));
        const month = parseInt(pythonDateString.substring(4, 6)) - 1; // Les mois dans JavaScript commencent à 0 (janvier = 0)
        const day = parseInt(pythonDateString.substring(6, 8));
        const hours = parseInt(pythonDateString.substring(8, 10));
        const minutes = parseInt(pythonDateString.substring(10, 12));
        const seconds = parseInt(pythonDateString.substring(12, 14));
        
        // Crée un nouvel objet Date avec les composants extraits
        return new Date(year, month, day, hours, minutes, seconds);
      }

    
      getdateFromCSVName(p_csvName) {
        return this.decodePythonDate(p_csvName.replace(/[a-zA-Z.]/g, ""));
        }

    
    // chargerLastCSV() {
    //     return new Observable(observer => {
    //         this.http.get('assets/lastcsv.txt', {responseType: 'text'})
    //         .subscribe(
    //             data => {
    //                 this.chargerCSV(data);
    //             },
    //             error => {
    //                 console.log(error);
    //             }
    //         );
    //     });
    // }
    chargerLastCSV(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.http.get('assets/lastcsv.txt', { responseType: 'text' }).subscribe(
                data => {
                    this.chargerCSV(data).then(() => resolve()).catch(error => reject(error));
                },
                error => {
                    console.log(error);
                    reject(error);
                }
            );
        });
    }

    
    // chargerCSV(p_filename){
    //         //on ne charge le csv que si on ne l'a pas déjà fait
    //         if(this.actualFilename != p_filename)
    //         {
    //             this.http.get('assets/'+p_filename, {responseType: 'text'})
    //         .subscribe(
    //             data => {
    //                     let csvToRowArray = data.split("\n");
    //                     for (let index = 1; index < csvToRowArray.length - 1; index++)
    //                     {
    //                         let row = csvToRowArray[index].split(";");
    //                         if (!isNaN(Number(row[5])))
    //                         {
    //                             this.productArray.push(new BeanAmazonProduct(row[0],
    //                                 row[1], row[2], row[3], row[4], Number(row[5]), Number(row[6]),
    //                                 row[7],row[8], row[9], Number(row[10]), Number(row[11]),Number(row[12]), row[13], row[14],row[15],Number(row[16]),Number(row[17])));
    //                         }
    //                     }
    //                     this.productArray = this.productArray.sort((a, b) => a.prixNeuf - b.prixNeuf);
    //                     // console.log(this.productArray);
    //                     // console.log("Fichier lu");
    //                     // console.log(csvToRowArray);
    //                 },
    //                 error => {
    //                     console.log(error);
    //                 }
    //             );
    //             this.actualFilename = p_filename;
    //             console.log(this.actualFilename);
    //             this.dateOfData = this.getdateFromCSVName(this.actualFilename);
    //         }   
    //     }
    chargerCSV(p_filename): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.actualFilename != p_filename) {
                this.http.get('assets/' + p_filename, { responseType: 'text' }).subscribe(
                    data => {
                        let csvToRowArray = data.split("\n");
                        for (let index = 1; index < csvToRowArray.length - 1; index++) {
                            let row = csvToRowArray[index].split(";");
                            if (!isNaN(Number(row[5]))) {
                                this.productArray.push(new BeanAmazonProduct(
                                    row[0], row[1], row[2], row[3], row[4], Number(row[5]), Number(row[6]),
                                    row[7], row[8], row[9], Number(row[10]), Number(row[11]), Number(row[12]),
                                    row[13], row[14], row[15], Number(row[16]), Number(row[17])
                                ));
                            }
                        }
                        this.productArray = this.productArray.sort((a, b) => a.prixNeuf - b.prixNeuf);
                        this.actualFilename = p_filename;
                        this.dateOfData = this.getdateFromCSVName(this.actualFilename);
                        resolve();
                    },
                    error => {
                        console.log(error);
                        reject(error);
                    }
                );
            } else {
                resolve();
            }
        });
    }

    // getProductByAsin(asin: string): BeanAmazonProduct | undefined {
    //     console.log(this.productArray.length);
    //     if(this.productArray.length == 0)
    //     {
    //         this.chargerLastCSV().subscribe(
    //             {
    //             console.log(this.productArray.length);
    //             return this.productArray.find(product => product.asin === asin);
    //             }
    //         );
    //     }
    // }
    getProductByAsin(asin: string): BeanAmazonProduct | undefined {
        if (this.productArray.length === 0) {
            this.chargerLastCSV().then(() => {
                return this.productArray.find(product => product.asin === asin);
            }).catch(error => {
                console.error('Error loading CSV:', error);
                return undefined;
            });
        } else {
            return this.productArray.find(product => product.asin === asin);
        }
    }
}