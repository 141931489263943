import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AmazonService } from '../services/Amazon.service';
import { BeanAmazonProduct} from "../services/BeanAmazonProduct";

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {

  product: BeanAmazonProduct | undefined;

  constructor(
    private route: ActivatedRoute,
    private AmazonService: AmazonService
  ) {}

  // async ngOnInit(): void {
  //   this.route.paramMap.subscribe(params => {
  //     const asin = params.get('asin');
  //     console.log(asin);
  //     if (asin) {
  //         await this.AmazonService.chargerLastCSV();
  //         this.product = this.AmazonService.getProductByAsin(asin);
  //         console.log(this.product);
  //       }
  //     });
  // }
  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(async params => {
      const asin = params.get('asin');
      if (asin) {
        await this.AmazonService.chargerLastCSV();
        this.product = this.AmazonService.getProductByAsin(asin);
        console.log(this.product);
      }
    });
  }

}
