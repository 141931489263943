export class BeanAmazonProduct{
  public asin: string;
  public lien: String;
  public marque: String;
  public ean: String;
  public modele: String;
  public garantie: String;
  public libelle: String;
  public prixNeuf: number;
  public monnaieNeuf: String;
  public prixUsed: number;
  public monnaieUsed: String;
  public ecran: String;
  public memoire: number;
  public batterie: number;
  public description: String;
  public showDetails: boolean;
  public is345g: number;
  public nbReview: number;
  public rating:number;

  constructor(p_asin: string,
              p_marque: String,
              p_ean: String,
              p_modele: String,
              p_garantie: String,
              p_prixNeuf: number,
              p_prixUsed: number,
              p_monnaieNeuf: String,
              p_monnaieUsed: String,
              p_ecran: String,
              p_memoire: number,
              p_batterie: number,
              p_is345g: number,
              p_lien: String,
              p_libelle: string, 
              p_description: String,
              p_nbReview: number,
              p_rating: number)
  {
    this.asin = p_asin;
    this.marque = p_marque;
    this.ean = p_ean;
    this.modele = p_modele;
    this.garantie = p_garantie;
    this.prixNeuf = p_prixNeuf;
    this.prixUsed = p_prixUsed;
    this.monnaieNeuf = p_monnaieNeuf;
    this.monnaieUsed = p_monnaieUsed;
    this.ecran = p_ecran;
    this.memoire = p_memoire;
    this.batterie = p_batterie;
    this.is345g = p_is345g;
    this.lien = p_lien;
    this.libelle = p_libelle;
    this.description = p_description;
    this.nbReview = p_nbReview;
    this.rating = p_rating;
    this.showDetails = false;
  }
}