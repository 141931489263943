import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { AmazonService } from '../services/Amazon.service';
import { BeanAmazonProduct} from "../services/BeanAmazonProduct";
import { forEach } from '@angular/router/src/utils/collection';
import { parseIntAutoRadix } from '@angular/common/src/i18n/format_number';

// interface navigator {
//   share?: (data?: { title?: string; text?: string; url?: string }) => Promise<void>;
// }

@Component({
  selector: 'app-liste-amazon',
  templateUrl: './liste-amazon.component.html',
  styleUrls: ['./liste-amazon.component.css']
})
export class ListeAmazonComponent implements OnInit {

  public productArray: BeanAmazonProduct[] = [];

  constructor(private AmazonService: AmazonService) { }

  ngOnInit() {
    this.AmazonService.chargerLastCSV();
    this.productArray = this.AmazonService.productArray; 
  }

  lastSortedByField = 'prixNeuf';
  ascendingOrder = true;
  filterText: string = '';

  isValidNumber(value: any): boolean {
    return !isNaN(value);
  }
  
  sortByField(field) {
    if(this.lastSortedByField === field) {
      this.ascendingOrder = !this.ascendingOrder;
    }
    else {
      this.lastSortedByField = field;
      this.ascendingOrder = true;
    }

    if(this.ascendingOrder) {
      this.productArray = this.productArray.sort((a, b) => {
        //if (this.lastSortedByField == "prixUsed")
        {
          if(isNaN(a[field]))
            return 1;
          if(isNaN(b[field]))
            return -1;
          if(isNaN(a[field]) && isNaN(b[field]))
            return 0;
        }
        if (a[field] < b[field])
          return -1;
        if (a[field] > b[field])
          return 1;
        return 0;
      });
    } else {
      this.productArray = this.productArray.sort((a, b) => {
        //if (this.lastSortedByField == "prixUsed")
        {
          if(isNaN(a[field]))
            return 1;
          if(isNaN(b[field]))
            return -1;
          if(isNaN(a[field]) && isNaN(b[field]))
            return 0;
        }
        if (a[field] < b[field])
          return 1;
        if (a[field] > b[field])
          return -1;
        return 0;
      });
    }
  }

  // Variable to store the selected filter value
  selectedFilterMarque: string = '';
  // Function to update the selected filter value
  updateFilterMarque(filterValue: string) {
    this.selectedFilterMarque = filterValue;
    this.FilteredData();
  }

  // Variable to store the selected filter value
  selectedFilterPrixMin: string = '';
  updateFilterPrixMin(filterValue: string) {
    this.selectedFilterPrixMin = filterValue;
    this.FilteredData();
  }
  selectedFilterPrixMax: string = '';
  updateFilterPrixMax(filterValue: string) {
    this.selectedFilterPrixMax = filterValue;
    this.FilteredData();
  }

  // Variable to store the selected filter value
  selectedFilterMemoire: string = '';
  updateFilterMemoire(filterValue: string) {
    this.selectedFilterMemoire = filterValue;
    this.FilteredData();
  }

  selectedFilteris345g: string = '';
  updateFilteris345g(filterValue: string) {
    this.selectedFilteris345g = filterValue;
    this.FilteredData();
  }
  

  // Function to get distinct labels
  getDistinctMarques() {
    // let labelCounts = {};
    // for (let item of this.AmazonService.productArray) {
    //   if (labelCounts[item.marque]) {
    //     labelCounts[item.marque]++;
    //   } else {
    //     labelCounts[item.marque] = 1;
    //   }
    // }
     return Array.from(new Set(this.AmazonService.productArray
      .filter(item => item.marque !== "")
      .map(item => item.marque)))
      .sort();
    //return Object.keys(labelCounts).filter(label => labelCounts[label] >= 5).sort();
  }

    // Function to get distinct 
  getDistinctMemoires() {
     return Array.from(new Set(this.AmazonService.productArray
                .filter(item => item.memoire !== 0)
                .map(item => item.memoire)))
                .sort((a, b) => {
      // Tri numérique, sinon j'avais un tri alphabétique
      return a - b;
    });
  }

  toggleDetails(item: any) {
    item.showDetails = !item.showDetails;
  }

  FilteredData() {
    this.productArray = []
    if (this.selectedFilterMarque === '') {
      this.productArray = this.AmazonService.productArray; // Return all data if no filter is selected
    } else {
      console.log("this.selectedFilterMarque = ",this.selectedFilterMarque);
      this.productArray = this.AmazonService.productArray.filter(item => item.marque === this.selectedFilterMarque);
    }

    ////Memoire
    if (this.selectedFilterMemoire === '') {
      // On ne filtre pas plus
    } else {
      console.log("this.selectedFilterMemoire = ",this.selectedFilterMemoire);
      this.productArray = this.productArray.filter(item => item.memoire >= parseInt(this.selectedFilterMemoire));
    }

    //// Prix
    if(this.selectedFilterPrixMin == '')
    {
      // On ne filtre pas plus
    }
    else
    {
      this.productArray = this.productArray.filter(item => item.prixNeuf >= parseFloat(this.selectedFilterPrixMin));
    }
    if(this.selectedFilterPrixMax == '')
    {
      // On ne filtre pas plus
    }
    else
    {
      this.productArray = this.productArray.filter(item => item.prixNeuf <= parseFloat(this.selectedFilterPrixMax));
    }

    ////Reseau
    if (this.selectedFilteris345g === '') {
      // On ne filtre pas plus
    } else {
      console.log("this.selectedFilteris345g = ",this.selectedFilteris345g);
      this.productArray = this.productArray.filter(item => item.is345g === parseInt(this.selectedFilteris345g));
    }

    ////Texte
    if (this.filterText === '') {
      // On ne filtre pas plus
    } else {
      const filter = this.filterText.toLowerCase();
      this.productArray = this.productArray.filter(item => item.libelle.toLowerCase().includes(filter));
    }
  }

  generateShareMessage(product: BeanAmazonProduct): string {
    console.log(product);
    let v_message = "https://trouvetonphone.fr";
    if(product)
    {
      v_message = `
        Découvrez ce produit sur Amazon:
        - Marque: ${product.marque}
        - Téléphone: ${product.libelle}
        - Prix Neuf: ${product.prixNeuf} ${product.monnaieNeuf}
        - Note: ${product.rating}/5 (${product.nbReview} avis)
        
        Consultez-le ici: https://trouvetonphone.fr/product/${product.asin}
      `;
    }
    return v_message;
    // return `
    //   Découvrez ce produit sur Amazon:
    //   - Marque: ${product.marque}
    //   - Téléphone: ${product.libelle}
    //   - Prix Neuf: ${product.prixNeuf} ${product.monnaieNeuf}
    //   - Prix d'occasion: ${product.prixUsed ? product.prixUsed + ' ' + product.monnaieUsed : 'N/A'}
    //   - Écran: ${product.ecran}
    //   - Mémoire: ${product.memoire} GB
    //   - Batterie: ${product.batterie} mAh
    //   - Description: ${product.description}
    //   - Note: ${product.rating}/5 (${product.nbReview} avis)
      
    //   Consultez-le ici: ${product.lien}
    // `;
  }

  share(p_asin: string) {
    let v_product = this.AmazonService.getProductByAsin(p_asin);
    console.log(v_product);
    let v_url = 'https://trouvetonphone.fr/product/'+v_product.asin;
    let shareData;
    if (v_product) {
      shareData = {
        title: "Ce téléphone peut tintéresser",
        text: this.generateShareMessage(v_product),
        url: v_url
      };
    }
    else
    {
      shareData = {
        title: "Ce comparateur de téléphone peut t'intéresser",
        text: "Voici un site qui peut t'intéresser :",
        url: "https://trouvetonphone.fr"
      };
    }

    if ((navigator as any).share) {
      (navigator as any).share(shareData).then(() => {
        console.log('Partage réussi',shareData);
      }).catch((error) => {
        console.error('Erreur de partage:', error);
      });
    } else {
      window.location.href = `mailto:?subject=${encodeURIComponent(shareData.title)}&body=${encodeURIComponent(shareData.text)}%0D%0A${encodeURIComponent(shareData.url)}`;
    }
  }

  shareByEmail(p_asin: string) {
    let v_product = this.AmazonService.getProductByAsin(p_asin);
    console.log(v_product);
    let subject;
    let body;
    if (!v_product) {
      subject= 'Ce comparateur de téléphone peut t\'intéresser';
      body= 'https://trouvetonphone.fr';
    }
    else
    {
      subject = encodeURIComponent('Ce téléphone peut t\'intéresser');
      body = encodeURIComponent(`${this.generateShareMessage(v_product)}`);
    }

    const  mailtoLink = `mailto:?subject=${subject}&body=${body}`;
    const a = document.createElement('a');
    a.href = mailtoLink;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
