import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListeAmazonComponent } from './liste-amazon/liste-amazon.component';
import { FourOhFourComponent } from './four-oh-four/four-oh-four.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';  // Importez votre composant

const routes: Routes = [
  { path: 'listeamazon',component: ListeAmazonComponent },
  { path: 'product/:asin', component: ProductDetailComponent },
  { path: '', component: ListeAmazonComponent },
  { path: 'not-found', component: FourOhFourComponent },
  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
